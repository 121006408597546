import React from 'react'
import styles from './commands.module.scss'
import { socials, about, about2 } from '../config'
import { Commands, Command } from '../typings'
import ListElement from '../ListElement/ListElement'

const UTILITIES = [
	{
		name: 'E-Messenger',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-messenger',
		description: 'Private Messaging',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-Crypt',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-crypt',
		description: 'Strongest Encryption',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-Box',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-box',
		description: 'Encrypted Files',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-Pass',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-pass',
		description: 'Store Secrets',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-TX',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-tx',
		description: 'Etherscan Messaging',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-Mixer',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-mixer',
		description: 'Funds Mixer',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-Scrow',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-scrow',
		description: 'Secure Escrow',
		icon: 'fab fa-fw fa-github',
	},
	{
		name: 'E-Bounty',
		link: 'https://mr-robot.gitbook.io/mrbot/mr-robots-ecosystem/e-bounty',
		description: 'Bug Bounty',
		icon: 'fab fa-fw fa-github',
	},
]

// -----------------------------------------

const tdStyle = {
	padding: '8px',
	borderBottom: '1px dashed #ddd',
}

const TableRow = ({
	variable,
	number,
}: {
	variable: string
	number: string
}) => (
	<tr>
		<td style={tdStyle}>{variable}</td>
		<td style={tdStyle}>{number}</td>
	</tr>
)

const rawCommands: Command[] = [
	{
		name: 'help',
		icon: 'fas fa-fw fa-question-circle',
		description: 'List all available commands',
		execute(app) {
			const { commands } = app.state
			return (
				<>
					<div style={{ marginTop: '10px' }}>
						Available commands:
						{[...commands.values()].map(
							({ icon, name, description }, key) => (
								<ListElement
									key={key}
									icon={icon}
									name={name}
									description={description}
									help
								/>
							)
						)}
					</div>
				</>
			)
		},
	},
	{
		name: 'about',
		icon: 'fas fa-fw fa-info-circle',
		description: 'Info about the project',
		execute(app) {
			const { userDataLoaded, userData } = app.state
			if (!userDataLoaded)
				return <>mrobot: user data could not be fetched</>
			const { avatar_url, name } = userData
			return (
				<div className={styles.aboutWrapper}>
					<div className={styles.aboutInner}>
						<img
							// src={avatar_url}
							src="/logoa.png"
							className={styles.avatar}
							alt="Avatar"
						/>
						<div className={styles.content}>
							<div className={styles.header}>
								<strong>{name}</strong>{' '}
								<span className="muted">@MROBOT</span>
							</div>
							<div className={styles.about}>{about}</div>
						</div>
					</div>

					<div className={styles.icons}>
						<i className="fab fa-fw fa-ethereum"></i>
					</div>
				</div>
			)
		},
	},
	{
		name: 'tokenomics',
		icon: 'fas fa-fw fa-tools',
		description: 'MROBOT Tokenomics',
		execute(app) {
			return (
				<div
					style={{
						marginTop: '20px',
						marginBottom: '20px',
					}}
				>
					<strong>Mr RoBOT $MRBOT contract details:</strong>
					<table
						style={{
							borderCollapse: 'collapse',
							width: '100%',
							textAlign: 'left',
							marginTop: '15px',
						}}
					>
						<thead>
							<tr>
								<th
									style={{
										// backgroundColor: '#f2f2f2',
										borderBottom: '2px dashed #999',
									}}
								>
									Variables
								</th>
								<th
									style={{
										// backgroundColor: '#f2f2f2',
										borderBottom: '2px dashed #999',
									}}
								>
									Numbers
								</th>
							</tr>
						</thead>
						<tbody>
							<TableRow variable="MRBOT" number="T.B.A" />
							<TableRow
								variable="Total Supply"
								number="10,000,000 $MRBOT"
							/>
							<TableRow
								variable="Circulating Supply"
								number="90% of total"
							/>
							<TableRow
								variable="CEX/marketing supply"
								number="10% of total"
							/>
							<TableRow
								variable="Max transaction"
								number="None"
							/>
							<TableRow variable="Max wallet" number="None" />
							<TableRow variable="Taxes" number="5/5" />
						</tbody>
					</table>

					<p style={{ textAlign: 'center', marginTop: '15px' }}>
						3% of the token's tax goes to marketing, 1% will go to
						infrastructure costs and 1% will be fed to our revenue
						share model.
					</p>
				</div>
			)
		},
	},
	{
		name: 'team',
		icon: 'fas fa-fw fa-users',
		description: 'Our background',
		execute() {
			return (
				<div className={styles.teamWrapper}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<img
							src="/team/team1.png"
							alt="Avatar"
							width="150px"
							height="150px"
						/>
						<p
							style={{ fontWeight: 'bold', marginTop: '25px' }}
							className=""
						>
							Edward Mr. Robot
						</p>
						<p
							className=""
							style={{ margin: '0px', fontWeight: 'bold' }}
						>
							The mastermind
						</p>
						<p
							className=""
							style={{
								margin: '0px',
								marginTop: '30px',
								textAlign: 'center',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Edward has an extensive background in computers with
							a huge interest in the crypto space and web3. His
							mission is to spread security awareness, as well as
							ensure that $MRBOT has the complete package for a
							top 100 token.
						</p>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<img
							src="/team/team2.png"
							alt="Avatar"
							width="150px"
							height="150px"
						/>
						<p
							style={{ fontWeight: 'bold', marginTop: '25px' }}
							className=""
						>
							Elliot Mr. Robot
						</p>
						<p
							className=""
							style={{ margin: '0px', fontWeight: 'bold' }}
						>
							The dev
						</p>
						<p
							className=""
							style={{
								margin: '0px',
								marginTop: '30px',
								textAlign: 'center',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Elliot has a deep understanding of web3 security,
							solidity, as well as great development skills when
							it comes to Telegram bots and Dapps. His mission is
							to make the space safer by offering a wealth of
							utilities that follow the best security practices.
						</p>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<img
							src="/team/team3.png"
							alt="Avatar"
							width="150px"
							height="150px"
						/>
						<p
							style={{ fontWeight: 'bold', marginTop: '25px' }}
							className=""
						>
							Darlene
						</p>
						<p
							className=""
							style={{ margin: '0px', fontWeight: 'bold' }}
						>
							The manipulative PR
						</p>
						<p
							className=""
							style={{
								margin: '0px',
								marginTop: '30px',
								textAlign: 'center',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Darlene has more than 10 years of experience in
							digital marketing for cryptocurrencies, enabling
							teams to connect with VCs, influencers, exchanges.
							With her marketing skills, her goal is to spread
							$MRBOT everywhere, making the crypto space safer.
						</p>
					</div>
				</div>
			)
		},
	},
	{
		name: 'utilities',
		icon: 'fas fa-fw fa-tools',
		description: 'Utilities in MRobot',
		execute() {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'left',
						width: '100%',
						marginTop: '20px',
						marginBottom: '20px',
					}}
				>
					<p>
						The Telegram bot is aimed to be the gateway to all our
						utilities, allowing you to connect the wallet holding
						$MRBOT as well for numerous reasons including revenue
						share in the future.
						<br />
						<br />
						Our own TG MRBOT's infrastructure, think of APIs,
						databases and so on, lives and runs within the TOR
						network, over multiple instances and nodes for increased
						availability. This provides our users with increased
						confidentiality over their actions and data, making it
						impossible even for Telegram to trace actions back to
						users, when using the bot.
						<br />
						<br />
						Each of the utilities are detailed in the following
						links:
					</p>

					<div className={styles.utilities__links}>
						{UTILITIES.map(
							({ icon, name, link, description }, key) => (
								<ListElement
									key={key}
									icon={icon}
									name={name}
									link={link}
									description={description}
								/>
							)
						)}
					</div>

					{/* <div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<img src="/logoa.png" alt="Avatar" width="150px" />
						<p className="">this is descriptkljaklfj</p>
					</div> */}
				</div>
			)
		},
	},
	{
		name: 'whitepaper',
		icon: 'fas fa-fw fa-file',
		description: 'Open whitepaper',
		execute(app) {
			window.open('/whitepaper v1.0.pdf', '_blank', 'noreferrer')
			return <>mrobot: opening whitepaper in new tab</>
		},
	},
	{
		name: 'socials',
		icon: 'fas fa-fw fa-link',
		description: 'All MRobot links',
		execute() {
			return (
				<>
					{socials.map(({ icon, name, link, description }, key) => (
						<ListElement
							key={key}
							icon={icon}
							name={name}
							link={link}
							description={description}
						/>
					))}
				</>
			)
		},
	},
	{
		name: 'cls',
		icon: 'fas fa-fw fa-eraser',
		description: 'Clear terminal screen',
		execute(app) {
			app.setState({
				...app.state,
				record: [],
			})
		},
	},
]
const commands: Commands = new Map(rawCommands.map(cmd => [cmd.name, cmd]))

export default commands
