import React, { Component, createRef, RefObject } from 'react'
import styles from './App.module.scss'
import commands from '../commands/commands'
import { projects, github_username } from '../config'
import { AppState } from '../typings'
import InputManager from '../InputManager/InputManager'

class App extends Component<{}, AppState> {
	mainRef: RefObject<any>
	audioRef1: RefObject<any>
	handleExecute: (arg: string) => void

	constructor(props: any) {
		super(props)
		this.state = {
			record: [],
			commands: commands,
			projectDataLoaded: false,
			userDataLoaded: false,
			videoEnded: false,
			device: '',
		}

		this.mainRef = createRef()
		this.audioRef1 = createRef()

		this.handleExecute = arg => {
			const { commands } = this.state
			const commandName = arg.trim()
			let output
			if (!commandName) output = <></>
			else if (!commands.has(commandName))
				output = <>mrobot: command not found: {commandName}</>
			else output = commands.get(commandName)?.execute(this)
			if (output)
				this.setState({
					...this.state,
					record: [
						...this.state.record,
						{
							command: commandName,
							output: output,
						},
					],
				})
		}
	}

	handlePlay = () => {
		this.audioRef1.current.play()
		this.audioRef1.current.muted = false
	}

	async componentDidMount() {
		this.checkWidth()
		window.addEventListener('resize', this.checkWidth)

		try {
			this.audioRef1.current
				.play()
				.then(() => {
					// console.log('run perfectly')
					// this.handlePlay()
				})
				.catch((error: any) => {
					// console.log('error ', error)
					// console.log('play promisse fails')
				})
		} catch (error) {
			console.log('try-catch fails')
		}

		const videoDiv = document.getElementById('video-div')
		const desktopVideo = document.getElementById('desktopVideo')
		if (desktopVideo && videoDiv) {
			desktopVideo.addEventListener('ended', () => {
				console.log('video ended')
				this.setState({
					...this.state,
					videoEnded: true,
				})
			})
		}

		// Fetch project data from github
		const promises = projects.map(project =>
			fetch(`https://api.github.com/repos/${project}`).then(res =>
				res.json()
			)
		)
		const projectData = []
		for (const promise of promises) projectData.push(await promise)
		const userData = await fetch(
			`https://api.github.com/users/${github_username}`
		).then(res => res.json())
		this.setState({
			...this.state,
			projectDataLoaded: true,
			projectData: projectData,
			userDataLoaded: true,
			userData: userData,
		})
	}

	componentDidUpdate(_: any, prevState: AppState) {
		// auto scroll
		if (
			prevState.record.length !== this.state.record.length &&
			this.mainRef?.current
		)
			this.mainRef.current.scrollTo({
				top: this.mainRef.current.scrollHeight,
				left: 0,
				behavior: 'smooth',
			})
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.checkWidth)
	}
	checkWidth = () => {
		const { device } = this.state
		const mobileBreakpoint = 600

		if (window.innerWidth <= mobileBreakpoint) {
			this.setState({ ...this.state, device: 'mobile' })
		} else if (window.innerWidth > mobileBreakpoint) {
			this.setState({ ...this.state, device: 'desktop' })
		}
	}

	render() {
		const { record, videoEnded, device } = this.state

		let videoSrc = ''
		if (device === 'mobile') {
			videoSrc = '/mobile-video.mp4'
		} else if (device === 'desktop') {
			videoSrc = '/desktop-video.mp4'
		}

		return (
			<>
				{!videoEnded && (
					<div
						id="video-div"
						// onMouseMove={this.handlePlay}
						onTouchStart={this.handlePlay}
						onClick={this.handlePlay}
					>
						<video
							id="desktopVideo"
							className={styles.video}
							playsInline
							autoPlay
							muted
							preload="auto"
							style={{
								width: '100vw',
								height: '100vh',
								objectFit: 'cover',
							}}
							ref={this.audioRef1}
						>
							<source src={videoSrc} type="video/mp4" />
						</video>
					</div>
				)}

				{videoEnded && (
					<div className={styles.wrapper} id="wrapper">
						<div className={styles.window}>
							<div className={styles.titleBar}>
								<div className={styles.dotHolder}>
									<div className={styles.dot}></div>
									<div className={styles.dot}></div>
									<div className={styles.dot}></div>
								</div>
								<div className={styles.titleHeader}>
									<i className="fa-fw fas fa-code"></i> MROBOT
								</div>
							</div>
							<div
								ref={this.mainRef}
								className={styles.mainContent}
							>
								{record.map(({ command, output }, index) => (
									<div key={index}>
										<span className={styles.promptPrefix}>
											<span>mrobot</span>@
											<span>root:</span>
											~${' '}
											<span
												className={
													commands.has(command)
														? styles.validCommand
														: styles.invalidCommand
												}
											>
												{command}
											</span>
										</span>
										<div>{output}</div>
									</div>
								))}
								<InputManager
									handleExecute={this.handleExecute}
								/>
							</div>
						</div>
					</div>
				)}
			</>
		)
	}
}

export default App
