import React from 'react'
const github_username = 'NessMongster'
const discord_usertag = ''
const discord_userid = ''
const email = 'mrroboteth@outlook.com'

const projects = [
	'NessMongster/BotClient',
	'NessMongster/Shellplate',
	'NessMongster/Fakestream',
	'NessMongster/ControlPanelAPI.js',
	'NessMongster/Deb8M8',
	'NessMongster/EmojiSteal',
]

const socials = [
	{
		name: 'Telegram',
		icon: 'fab fa-fw fa-telegram',
		link: 'https://t.me/MRBOTportal',
		description: 'Telegram',
	},
	{
		name: 'Bot',
		icon: 'fab fa-fw fa-docker',
		link: 'https://t.me/ThisIsMRBOT',
		description: 'MRBot',
	},
	{
		name: 'Twitter',
		icon: 'fab fa-fw fa-twitter',
		link: 'https://twitter.com/ThisIsMRBOT',
		description: 'Twitter',
	},
	{
		name: 'Dextools',
		icon: 'fas fa-fw fa-coffee',
		link: 'https://www.dextools.io/app/en/ether/pair-explorer/0x43c0bf31fa491a34ea383566bdb799e00a23e211',
		description: 'Chart',
	},
	{
		name: 'Uniswap',
		link: `https://app.uniswap.org/swap?outputCurrency=0x22112bbfc05d56bf9a4af69335e2d7acf06d5e0f&chain=ethereum`,
		icon: 'fab fa-fw fa-ethereum',
		description: `Get $MROBOT!`,
	},
	{
		name: 'E-mail',
		icon: 'fas fa-fw fa-at',
		link: `mailto:${email}`,
		description: 'Lets get in touch!',
	},
]

const about = (
	<>
		<p>From the well-known award winning TV series Mr. Robot</p>
		<p>
			We decided to build an all-in-one suite for all crypto investors'{' '}
			<span className="highlighted">security needs.</span> $MROBOT is a{' '}
			<span className="highlighted">multi-security solution.</span>.
		</p>
		<p>
			Holders gain access to the{' '}
			<span className="highlighted">Mr. Robot bot</span> that provides a large variety of security tools for secure and encrypted communications, secure file exchange and secure fund management, all directed towards a{' '}
			<span className="highlighted">complete decentralized, private and anonymous ecosystem</span> that traders and developers can use for most of their day-to-day needs in{' '}
			<span className="highlighted">crypto space.</span>
		</p>
	</>
)

const about2 = (
	<>
		<p>TOKENOMICS TOKENOMICS TOKENOMICS </p>
		<p>
			TOKENOMICS TOKENOMICS TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS </span> TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS </span>.
		</p>
		<p>
			TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS </span> TOKENOMICS TOKENOMICS TOKENOMICS TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS </span> TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS </span>
		</p>
		<p>
			TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS </span>,{' '}
			<span className="highlighted">TOKENOMICS </span> TOKENOMICS {' '}
			<span className="highlighted">TOKENOMICS .</span>
		</p>
	</>
)

export {
	github_username,
	discord_usertag,
	discord_userid,
	email,
	projects,
	socials,
	about,
	about2,
}
